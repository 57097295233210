import React from 'react'
import './Animatedbg.css'
export default function Animatedbg() {
    return (
        <div>
            <div className='stars'></div>
            <div className='twinkling'></div>
        </div>
    )
}
